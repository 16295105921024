"use client";
import ContactUs from "@/components/feature-page/contact-us/ContactUsComp";
import { Box, Button, Container } from "@mantine/core";
import { IconChevronUp } from "@tabler/icons-react";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";

const OurService = dynamic(
  () => import("@/components/feature-page/our-service/OurService")
);
const AboutUs = dynamic(
  () => import("@/components/feature-page/about-us/AboutUs")
);
const CtaComp = dynamic(() => import("@/components/feature-page/cta/CtaComp"));
const OurProjects = dynamic(
  () => import("@/components/feature-page/our-projects/OurProjects")
);
const ClientFeedback = dynamic(
  () => import("@/components/feature-page/client-feedback/ClientFeedback")
);

const Main = () => {
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setShowScrollButton(scrollPosition > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box bg="background.0" px={{ lg: 80, md: 56, sm: 32 }}>
      <Container size="xl">
        <AboutUs />
        <OurService />
        <CtaComp />
        <OurProjects />
        <ClientFeedback />
        <ContactUs />
        {showScrollButton && (
          <Button
            h={{ base: 40, xl: 60, md: 50, sm: 45 }}
            w={{ base: 40, xl: 60, md: 50, sm: 45 }}
            p={10}
            radius="50%"
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              zIndex: 100,
            }}
            onClick={scrollToTop}
            color="#background.2"
            bd="1px solid #0066FF"
          >
            <IconChevronUp
              // stroke={2}
              color="#0066FF"
              // fill="#0066FF"
              size={40}
            />
          </Button>
        )}
      </Container>
    </Box>
  );
};

export default Main;
