import React, { useRef, useState } from "react";
import { useForm } from "@mantine/form";
import { TextInput, Textarea, SimpleGrid, Group } from "@mantine/core";
import emailjs from "@emailjs/browser";
import CoreButton from "@/components/core-components/core-button/CoreButton";
import { notifications } from "@mantine/notifications";
import { IconChevronRight } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

interface IFContactForm {
  name: string;
  email: string;
  message: string;
}

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSmallScreen = useMediaQuery("(min-width: 576px)");

  const formRef = useRef<HTMLFormElement>(null);
  const emailJsServiceId = process.env.EMAIL_JS_SERVICE_ID as string;
  const emailJsTemplateId = process.env.EMAIL_JS_TEMPLATE_ID as string;
  const emailJsPublicKey = process.env.EMAIL_JS_PUBLIC_KEY as string;

  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate: {
      name: (value) =>
        value.trim().length < 3 && "Name must be at least 3 characters",
      email: (value) =>
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) &&
        "Invalid email address",
      message: (value) =>
        value.trim().length < 10 && "Project must be at least 10 characters",
    },
  });

  const inputStyles = {
    input: {
      background: "#161518",
      color: "#fff",
      border: "1px solid #2B2B2B",
    },
  };

  const onFormSubmit = async (values: IFContactForm) => {
    setIsLoading(true);
    console.log(values);
    if (formRef.current) {
      try {
        await emailjs.sendForm(
          emailJsServiceId,
          emailJsTemplateId,
          formRef.current,
          {
            publicKey: emailJsPublicKey,
          }
        );
        notifications.show({
          message: "Email successfully sent",
          color: "green",
          position: "top-right",
        });
        console.log("Email successfully sent!", values);
        setIsLoading(false);
        form.reset();
      } catch (error) {
        notifications.show({
          message: "Failed to send email",
          color: "red",
          position: "top-right",
        });
        console.error("Failed to send email:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <form
      ref={formRef}
      onSubmit={form.onSubmit((values) => onFormSubmit(values))}
    >
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        <TextInput
          placeholder="Your Name"
          name="name"
          radius={10}
          size="lg"
          variant="filled"
          styles={inputStyles}
          {...form.getInputProps("name")}
        />

        <TextInput
          placeholder="Your Email"
          name="email"
          variant="filled"
          styles={inputStyles}
          radius={10}
          size="lg"
          {...form.getInputProps("email")}
        />
      </SimpleGrid>

      <Textarea
        mt="xl"
        placeholder="Project Description"
        maxRows={10}
        minRows={6}
        radius={10}
        autosize
        name="message"
        variant="filled"
        size="lg"
        styles={inputStyles}
        {...form.getInputProps("message")}
      />

      <Group justify="left" mt="xl">
        <CoreButton
          title="Let's Talk"
          variant="primary"
          type="submit"
          fullWidth={isSmallScreen ? false : true}
          rightSection={<IconChevronRight size={20} stroke={4} />}
          loading={isLoading}
        />
      </Group>
    </form>
  );
};

export default ContactForm;
