import React from "react";
import Typewriter from "typewriter-effect";
import { Box, Flex } from "@mantine/core";
import { anta } from "@/app/fonts";

interface TypewriterComponentProps {
  texts: string[];
  loop?: boolean;
  speed?: number;
  delay?: number;
}

const TypewriterComponent: React.FC<TypewriterComponentProps> = ({
  texts,
  loop = true,
  speed = 50,
  delay = 100,
}) => {
  return (
    <Flex justify="center">
      <Box
        style={{ display: "inline-block" }}
        py={0}
        px={5}
        fz={{ md: 58, sm: 42, base: 36 }}
        lh={{ md: "73px", sm: "55px", base: "50px" }}
        c="brand.5"
        bg="brand.0"
        className={anta.className}
      >
        <Typewriter
          options={
            {
              strings: texts,
              autoStart: true,
              loop: loop,
              delay: speed,
              pauseFor: delay,
              cursor: "",
            } as any
          }
        />
      </Box>
    </Flex>
  );
};

export default TypewriterComponent;
