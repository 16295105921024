"use client";
import { Box, Flex, Text } from "@mantine/core";
import CoreButton from "@/components/core-components/core-button/CoreButton";
import { motion } from "framer-motion";
import { IconChevronRight } from "@tabler/icons-react";
import { anta } from "@/app/fonts";
import TypewriterComponent from "@/components/common/typewriter/TypewriterComponent";
// import ClientMarquee from "@/components/common/client-marquee/ClientMarquee";

const HeroSection = () => {
  const scrollToContactUs = () => {
    const contactUsElement = document.getElementById("contact");
    if (contactUsElement) {
      contactUsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToProjects = () => {
    const contactUsElement = document.getElementById("portfolio");
    if (contactUsElement) {
      contactUsElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  // const waveContainerVariants = {
  //   animate: {
  //     transition: {
  //       delay: 0.5,
  //       delayChildren: 0.1,
  //       staggerChildren: 0.2,
  //     },
  //   },
  // };

  const chipVariants = {
    initial: { scale: 0.5, opacity: 0 },
    animate: {
      scale: 1,
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 0.7,
        ease: "easeInOut",
      },
    },
  };

  const fadeInFromLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 0.5,
        duration: 0.7,
        ease: "easeOut",
      },
    },
  };

  const textVariants = {
    hidden: {
      clipPath: "inset(0 50% 0 50%)",
      opacity: 0,
    },
    visible: {
      clipPath: "inset(0 0% 0 0%)",
      opacity: 1,
      transition: {
        delay: 0.5,
        duration: 1,
        ease: "easeInOut",
      },
    },
  };

  const firstButtonVariants = {
    hidden: { opacity: 0, y: 25 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.5,
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  const secondButtonVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delay: 0.5,
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  // const imageVariants = {
  //   hidden: { opacity: 0 },
  //   visible: {
  //     opacity: 1,
  //     transition: {
  //       delay: 1.2,
  //       duration: 2,
  //       ease: "easeOut",
  //     },
  //   },
  // };

  return (
    <Box
      id="home"
      style={{
        backgroundImage: "url(/assets/images/hero-bg.webp)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <Box
        px={{ lg: 80, md: 56, sm: 32, base: 16 }}
        py={{ lg: 80, md: 80, sm: 40, base: 16 }}
      >
        <Flex direction="column" gap={20}>
          <Flex direction="column" gap={16} align="center">
            <motion.div
              variants={chipVariants}
              style={{
                background:
                  "linear-gradient(90deg, rgba(156, 156, 156, 0.10) 28.85%, rgba(156, 156, 156, 0.10) 99.33%)",
                padding: "4px 9px",
                borderRadius: "3px",
                border: "0.5px solid #353535",
              }}
            >
              <Text fz="14px" fw={500} c="#8F8F8F">
                Complete Tech-Solution Company
              </Text>
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeInFromLeft}
              style={{ width: "100%" }}
            >
              <Text
                p={0}
                fz={{ md: 58, sm: 42, base: 36 }}
                fw={400}
                lh={{ md: "73px", sm: "55px", base: "50px" }}
                c="white"
                ta="center"
                m="0 auto"
                w={{ xl: "80%", base: "100%" }}
                className={anta.className}
              >
                We Create Solutions that&nbsp;
              </Text>
              <TypewriterComponent
                texts={[
                  "Drive Growth",
                  "Deliver Results",
                  "Reshape Brands",
                  "Build Identity",
                ]}
                speed={150}
                delay={100}
              />
            </motion.div>

            <motion.div
              initial="hidden"
              animate="visible"
              variants={textVariants}
              style={{ transformOrigin: "center" }}
            >
              <Text
                lh="22px"
                lts="0.16px"
                c="#DBDBDB"
                ta="center"
                m="0 auto"
                fz={{ xs: "16px", base: "14px" }}
                fw={500}
                w={{ xs: "68%", base: "100%" }}
              >
                {/* At VintSolution, we don’t just build websites — we build digital
                experiences. From compelling UI/UX that tells brand stories, to
                stunning Web Design and innovative Marketing strategies that
                boost engagement and revenue — our full-cycle services cover it
                all. */}
                At Vint Solution, we bring your business to the digital world.
                By providing custom solutions, we help you in boosting brand’s
                visibility and drive success. Ready to give your brand a fresh
                look?
              </Text>
            </motion.div>
          </Flex>
          <Flex justify="center" mt={12}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={secondButtonVariants}
            >
              <CoreButton
                title="Let's Chat"
                rightSection={<IconChevronRight size={20} stroke={4} />}
                variant="primary"
                onClick={scrollToContactUs}
              />
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={firstButtonVariants}
            >
              <CoreButton
                title="See Portfolio"
                variant="underline"
                onClick={scrollToProjects}
              />
            </motion.div>
          </Flex>
        </Flex>
      </Box>
      {/* <ClientMarquee /> */}
    </Box>
  );
};

export default HeroSection;
