const InstaSvg = () => {
  return (
    <svg
      width="42"
      height="41"
      viewBox="0 0 42 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="0.5" width="40" height="40" rx="8" fill="#161518" />
      <rect x="1" y="0.5" width="40" height="40" rx="8" stroke="#282828" />
      <g clipPath="url(#clip0_2970_67)">
        <path
          d="M27 8.5H15C11.7003 8.5 9 11.1991 9 14.5V26.5C9 29.7997 11.7003 32.5 15 32.5H27C30.2997 32.5 33 29.7997 33 26.5V14.5C33 11.1991 30.2997 8.5 27 8.5ZM21 25.4998C18.2382 25.4998 15.9999 23.2606 15.9999 20.5C15.9999 17.7382 18.2382 15.4999 21 15.4999C23.7606 15.4999 26.0001 17.7382 26.0001 20.5C26.0001 23.2606 23.7606 25.4998 21 25.4998ZM27.5001 15.4999C26.6709 15.4999 26.0001 14.8282 26.0001 13.9999C26.0001 13.1716 26.6709 12.4999 27.5001 12.4999C28.3293 12.4999 29.0001 13.1716 29.0001 13.9999C29.0001 14.8282 28.3293 15.4999 27.5001 15.4999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2970_67">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(9 8.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default InstaSvg;
