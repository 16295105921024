import { Chip } from "@mantine/core";

interface CoreChipsProps {
  title: string;
  radius?: string | number;
  fontSize?: string | number;
  backgroundColor?: string;
  color?: string;
  border?: string;
}

const CoreChips = ({
  title,
  radius = "sm",
  fontSize = 16,
  backgroundColor = "#29282a",
  color = "#0066FF",
  border = "none",
}: CoreChipsProps) => {
  return (
    <Chip
      checked={false}
      variant="outline"
      size="md"
      tt="uppercase"
      fw={500}
      radius={radius}
      styles={{
        label: {
          color: color,
          backgroundColor: backgroundColor,
          border: border,
          padding: "8px 12px",
          fontSize: fontSize,
        },
      }}
    >
      {title}
    </Chip>
  );
};

export default CoreChips;
