const EmailSvg = () => {
  return (
    <svg width="44" height="42" viewBox="0 0 44 42" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1.17041" width="42" height="40" rx="8" stroke="#282828" />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.75736 10.9278C7 12.6851 7 15.5136 7 21.1704C7 26.8273 7 29.6557 8.75736 31.4131C10.5147 33.1704 13.3431 33.1704 19 33.1704H25C30.6569 33.1704 33.4853 33.1704 35.2426 31.4131C37 29.6557 37 26.8273 37 21.1704C37 15.5136 37 12.6851 35.2426 10.9278C33.4853 9.17041 30.6569 9.17041 25 9.17041H19C13.3431 9.17041 10.5147 9.17041 8.75736 10.9278ZM31.8643 14.4502C32.262 14.9275 32.1975 15.6369 31.7202 16.0347L28.4255 18.7803C27.0959 19.8882 26.0183 20.7863 25.0672 21.398C24.0765 22.0352 23.1116 22.4377 22 22.4377C20.8884 22.4377 19.9235 22.0352 18.9328 21.398C17.9817 20.7863 16.9041 19.8883 15.5745 18.7803L12.2798 16.0347C11.8025 15.6369 11.738 14.9275 12.1358 14.4502C12.5335 13.9729 13.2429 13.9084 13.7202 14.3062L16.9586 17.0048C18.358 18.171 19.3296 18.978 20.1499 19.5056C20.9439 20.0163 21.4824 20.1877 22 20.1877C22.5176 20.1877 23.0561 20.0163 23.8501 19.5056C24.6704 18.978 25.642 18.171 27.0415 17.0048L30.2798 14.3062C30.7571 13.9084 31.4665 13.9729 31.8643 14.4502Z" fill="white" />
    </svg>

  );
};
export default EmailSvg;
