const PhoneSvg = () => {
  return (
    <svg
      width="42"
      height="43"
      viewBox="0 0 42 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="1" width="41" height="41" rx="8.5" fill="#161518" />
      <rect x="0.5" y="1" width="41" height="41" rx="8.5" stroke="#282828" />
      <path
        d="M18.0564 11.4743L19.0299 13.2187C19.9085 14.7929 19.5558 16.858 18.1721 18.2417C18.1721 18.2417 18.1721 18.2417 18.1721 18.2417C18.1719 18.2418 16.4938 19.9202 19.5368 22.9632C22.5791 26.0056 24.2575 24.3287 24.2583 24.3279C24.2583 24.3279 24.2583 24.3279 24.2583 24.3279C25.642 22.9442 27.7071 22.5915 29.2814 23.4701L31.0257 24.4436C33.4028 25.7702 33.6836 29.1039 31.5942 31.1933C30.3387 32.4488 28.8006 33.4257 27.1004 33.4902C24.2382 33.5987 19.3774 32.8743 14.5016 27.9984C9.62569 23.1226 8.90132 18.2618 9.00983 15.3996C9.07428 13.6994 10.0512 12.1614 11.3067 10.9058C13.3961 8.81642 16.7298 9.09716 18.0564 11.4743Z"
        fill="white"
      />
    </svg>
  );
};
export default PhoneSvg;
